import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-rental-login',
  templateUrl: './rental-login.component.html',
  styleUrls: ['./rental-login.component.scss']
})
export class RentalLoginComponent implements OnInit {
  loginForm: FormGroup;
  loginButtonDisable = false
  submitted = false;
  private clickTimeout: any = null;
  public showPassword: boolean;
  logo: string;
  regButon: boolean = true;
  url_youtube: any;
  url_insta: any;
  url_telegram: any;
  constructor(private router: Router,
    private fb: FormBuilder, private toastr: ToastrService, private socket: Socket) { }

  ngOnInit(): void {
    this.changeIcon();
    this.createFrom();
  }

  async findHostName() {
    return (window.location.hostname);
  }

  async changeIcon() {
    const hostname = await this.findHostName();
    const splithostname = hostname.split('.')
    this.logo = splithostname[0];
    this.setManager();
    this.checkShowReg();
  }

  createFrom() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      manager: ''
    })
  }

  setManager() {
    this.loginForm.patchValue({ manager: 'AOBAOB' });
  }

  checkShowReg() {
    if (this.logo === 'Betx' || this.logo === 'paisaexch')
      this.regButon = false;
    else {
      this.regButon = true;
    }
  }


  get f() { return this.loginForm.controls; }

  async onSignInClick() {
    this.loginButtonDisable = true;
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      // if timeout doesn't exist, we know it's first click 
      // treat as single click until further notice
      this.setClickTimeout(() =>
        this.handleSingleLoginClick());
    }
  }
  public handleSingleLoginClick() {
    //The actual action that should be performed on click      
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.loginButtonDisable = false;
      return;
    }

    const loginData = { user: this.loginForm.value }

    this.socket.emit('login', loginData);

    this.socket.on('login-error', (function (o: any) {
      this.toastFunction(o.message);
      this.loginButtonDisable = false;
    }).bind(this));
    this.socket.on('get-login-status', (function (error: any) {
      // Unable to log in
      this.toastFunction(error.message);
      this.loginButtonDisable = false;
    }).bind(this));
    this.socket.on('login-success', (function (data: any) {

      const output = data.output;
      console.log(output);


      if (output) {
        // Todo: validate output 
        this.loginButtonDisable = false;
        sessionStorage.setItem('loginStatus', 'true');
        sessionStorage.setItem('userDetails', JSON.stringify(output));
        if (output.details.manager == "OSG365") {
          this.redirectToWallet();
        } else {
          this.redirectToInplay();
        }

      }

    }).bind(this));

  }

  async redirectToWallet() {
    var acctoken = await sessionStorage.getItem('loginStatus');

    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['/wallet']);
    }
  }

  async redirectToInplay() {
    var acctoken = await sessionStorage.getItem('loginStatus');

    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard']);
    }
  }


  // sets the click timeout and takes a callback 
  // for what operations you want to complete when
  // the click timeout completes
  public setClickTimeout(callback: any) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }

  downloadApk() {
    window.open('https://bit.ly/3VlQNFM', "_blank");
  }

  toastFunction(msg: any) {
    this.toastr.error(msg + ' !');
  }

}
